const menu = [
  {
    headTitle1: "Reports",
    type: "headtitle",
  },
  {
    title: "Order",
    icon: "file-text",
    badgeType: "light-primary",
    badgeValue: "2",
    active: false,
    type: "link",
    path: "/report/order",
  },
  {
    title: "Delivery",
    icon: "file-text",
    badgeType: "light-primary",
    badgeValue: "2",
    active: false,
    type: "link",
    path: "/report/delivery",
  },
];

export default menu;
