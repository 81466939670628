<template>
  <div class="container-fluid mt-4">
    <!-- <h3>Welcome Back, {{ Profile.firstName }}!!</h3> -->

    <div class="row">
      <StatCard title="Jumlah User AksesToko" :value="akestoko_users" unit="Toko" />
      <StatCard title="Jumlah User AksesToko (Terverifikasi)" :value="akestoko_users_verified" unit="Toko" />
      <StatCard title="Jumlah User AksesToko (Belum Terverifikasi)" :value="akestoko_users_unverified" unit="Toko" />
      <StatCard title="Jumlah Distributor" :value="distributors" unit="Distributor" />
      <StatCard title="Jumlah Salesman" :value="salesmen" unit="Salesman" />
    </div>

    <!-- Charts -->
    <div class="row">
      <div class="col-md-6">
        <ChartCardTotalOrder title="Jumlah Order (Semua Status Harian)" style="height: 520px;" />
      </div>
      <div class="col-md-6">
        <ChartCardTotalOrderBulanan title="Jumlah Order (Semua Status Bulanan)" style="height: 520px;" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <ChartJumlahOrderByRegion title="Jumlah Order By Region (Bulanan)" style="height: 520px;" />
      </div>
    </div>

    <!-- Charts -->
    <div class="row">
      <div class="col-md-6">
        <ChartCardTokoOrder title="Grafik Jumlah Toko Order (Harian)" style="height: 520px;" />
      </div>
      <div class="col-md-6">
        <ChartCardTokoOrderBulanan title="Grafik Jumlah Toko Order (Bulanan)" style="height: 520px;" />
      </div>
    </div>

    <div class="row row-cols-5">
      <StatCard title="Storage digunakan" :value="storage" unit="GB" />
      <StatCard title="Jumlah Berita/Artikel" :value="articles" unit="Konten" />
      <StatCard title="Jumlah Banner" :value="banners" unit="Banner" />
      <StatCard title="Jumlah Promosi" :value="promotions" unit="Promosi" />
      <StatCard title="Jumlah Produk" :value="products" unit="Produk" />
    </div>

    <!-- Charts -->
    <div class="row">
      <div class="col-md-6">
        <ChartCardRegistration title="Grafik Jumlah Registrasi (Harian)" style="height: 520px;" />
      </div>
      <div class="col-md-6">
        <ChartCardRegistrationBulanan title="Grafik Jumlah Registrasi (Bulanan)" style="height: 520px;" />
      </div>
    </div>

    <!-- Other Stats -->
    <!-- <div class="row row-cols-3">
      <StatCard title="Toko Universe (MDXL, Aktif)" :value="toko_mdxl" unit="Toko" />
      <StatCard title="Distributor Universe (MDXL, Aktif)" :value="distributor_mdxl" unit="Distributor" />
      <StatCard title="Salesman Universe (MDXL, Aktif)" :value="salesman_mdxl" unit="Salesman" />
    </div> -->

    <!-- More Charts -->
    <div class="row">
      <div class="col-md-6">
        <ChartCardTokoAktifHarian title="Grafik Jumlah Toko Aktif (Harian)" style="height: 520px;" />
      </div>
      <div class="col-md-6">
        <ChartCardTokoAktifBulanan title="Grafik Jumlah Toko Aktif (Bulanan)" style="height: 520px;" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <TableDistributorByGMV title="Top 10 Distributor by GMV" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import StatCard from "../default/StatCard.vue";
import ChartCard from "../default/ChartCard.vue";
import ChartCardRegistration from "../default/ChartCardRegistration.vue";
import ChartCardTotalOrder from "../default/ChartCardTotalOrder.vue";
import ChartCardTokoAktifHarian from "../default/ChartCardTokoAktifHarian.vue";
import ChartCardTokoAktifBulanan from "../default/ChartCardTokoAktifBulanan.vue";
import ChartCardTotalOrderBulanan from "./ChartCardTotalOrderBulanan.vue";
import ChartCardRegistrationBulanan from "./ChartCardRegistrationBulanan.vue";
import ChartCardTokoOrder from "./ChartCardTokoOrder.vue";
import ChartCardTokoOrderBulanan from "./ChartCardTokoOrderBulanan.vue";
import ChartJumlahOrderByRegion from "./ChartJumlahOrderByRegion.vue";
import TableDistributorByGMV from "./TableDistributorByGMV.vue";

export default {
  components: { StatCard, ChartCard, ChartCardRegistration, ChartCardRegistrationBulanan, 
    ChartCardTotalOrder, ChartCardTotalOrderBulanan, ChartCardTokoAktifHarian, ChartCardTokoAktifBulanan,
    ChartCardTokoOrder, ChartCardTokoOrderBulanan, ChartJumlahOrderByRegion, TableDistributorByGMV},
  data() {
    return {
      akestoko_users: 0,
      akestoko_users_verified: 0,
      akestoko_users_unverified: 0,
      distributors: 0,
      salesmen: 0,
      articles: 0,
      banners: 0,
      promotions: 0,
      products: 0,
      storage: 0,
      toko_mdxl: 0,
      distributor_mdxl: 0,
      salesman_mdxl: 0,
      Profile: []
    }
  },
  mounted() {
    this.Profile = JSON.parse(localStorage.getItem('user'));
  },
  async created() {
    axios
      .get("/admin-api/dashboard/get-total-data", {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        params: {
          parameters: [
            { type_name: "akestoko_users" },
            { type_name: "akestoko_users_verified" },
            { type_name: "akestoko_users_unverified" },
            { type_name: "distributors" },
            { type_name: "salesmen" },
            { type_name: "articles" },
            { type_name: "banners" },
            { type_name: "promotions" },
            { type_name: "products" },
            { type_name: "storage" },
          ],
        },
      })
      .then((x) => {
        this.akestoko_users = x.data.data.totalData.akestoko_users;
        this.akestoko_users_verified = x.data.data.totalData.akestoko_users_verified;
        this.akestoko_users_unverified = x.data.data.totalData.akestoko_users_unverified;
        this.distributors = x.data.data.totalData.distributors;
        this.salesmen = x.data.data.totalData.salesmen;
        this.articles = x.data.data.totalData.articles;
        this.banners = x.data.data.totalData.banners;
        this.promotions = x.data.data.totalData.promotions;
        this.products = x.data.data.totalData.products;
        this.storage = x.data.data.totalData.storage;
      });
  }
};
</script>

<style>
.container {
  max-width: 1200px;
}
</style>
