<template>
    <div class="card p-3">
        <h6>{{ title }}</h6>
        <DateFilter @updateFilter="handleDateFilter" />
        <div class="chart-placeholder">
            <GChart class="chart-overflow" id="line-chart1" :settings="settings" :data="lineChartData"
                :options="lineChartOptions" :createChart="(el, google) => new google.visualization.LineChart(el)"
                @ready="onChartReady" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { GChart } from "vue-google-charts";
import DateFilter from '../default/DateFilter.vue';

export default {
    components: { GChart, DateFilter },
    props: ["title", "timeFilters"],
    data() {
        return {
            chartsLib: null,
            lineChartData: [['Date', 'Registrasi']],
            settings: { packages: ['corechart'] },
            periode: ""
        };
    },
    async created() {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 7);
        
        const formattedRange = [startDate.toLocaleDateString('en-CA'), endDate.toLocaleDateString('en-CA')];
        this.getDataRegistration(formattedRange[0], formattedRange[1]);
    },
    computed: {
        lineChartOptions() {
            if (!this.chartsLib) return null;
            return {
                title: "Jumlah Registrasi",
                subtitle: "Total Jumlah Registrasi Periode " + this.periode,
                curveType: 'linear',
                legend: { position: 'bottom' },
                height: 400,
                width: "100%",
                colors: ["#24695c"],
                pointSize: 5,
                annotations: {
                    textStyle: {
                        fontSize: 12,
                        color: '#000',
                    }
                },
                vAxis: {
                    format: "0",
                    viewWindow: { min: 0 },
                },
            };
        }
    },
    methods: {
        onChartReady(chart, google) {
            this.chartsLib = google;
        },
        getDataRegistration(start, end) {
            axios
            .get("/admin-api/dashboard/get-total-registration", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                params: {
                    startDate: start,
                    endDate: end,
                },
            })
            .then((response) => {
                const totalRegistration = response.data.data.totalRegistration;
                this.lineChartData = [['Date', 'Registrasi', { role: 'annotation' }]];

                for (const key in totalRegistration) {
                    this.lineChartData.push([
                        totalRegistration[key].date_formatted,
                        totalRegistration[key].value,
                        totalRegistration[key].value.toString() // Annotation
                    ]);
                }
                this.periode = `${start} - ${end}`;
            });
        },
        handleDateFilter(filter) {
            this.getDataRegistration(filter.value[0], filter.value[1]);
        }
    }
};
</script>

<style scoped>
.chart-placeholder {
    height: 150px;
    background: #f8f9fa;
    border-radius: 5px;
    text-align: center;
    line-height: 150px;
    color: gray;
}
</style>
