<template>
    <div class="card p-3">
        <h6>{{ title }}</h6>
        <MonthFilter @updateFilter="handleMonthFilter" />
        <div class="chart-placeholder">
            <GChart class="chart-overflow" id="line-chart1" :settings="settings" :data="line_chart.chartData"
                :options="lineChartOptions" :createChart="(el, google) => new google.visualization.LineChart(el)"
                @ready="onChartReady" />
        </div>

        <div v-if="showModal" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
            <div class="bg-white p-6 rounded-lg shadow-lg">
                <h3 class="text-lg font-semibold mb-3">Pilih Rentang Tanggal</h3>
                <DatePicker v-model="dateRange" range />
                <div class="mt-4 flex justify-end gap-2">
                    <button @click="showModal = false" class="px-4 py-2 bg-gray-300 rounded">Cancel</button>
                    <button @click="applyDateRange" class="px-4 py-2 bg-green-700 text-white rounded">Apply</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { GChart } from "vue-google-charts";
import MonthFilter from '../default/MonthFilter.vue';

export default {
    components: { GChart, MonthFilter },
    props: ["title", "timeFilters"],
    data() {
        return {
            showModal: false,
            chartsLib: null,
            line_chart: {},
            settings: { packages: ['corechart'] },
            periode: ""
        };
    },
    async created() {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 3);

        const formattedRange = [startDate.toLocaleDateString('en-CA'), endDate.toLocaleDateString('en-CA')];
        this.getDataRegistrasi(formattedRange[0], formattedRange[1]);
    },
    computed: {
        lineChartOptions() {
            if (!this.chartsLib) return null;
            return {
                title: "Jumlah Registrasi Bulanan",
                subtitle: "Total Jumlah Registrasi Periode " + this.periode,
                curveType: 'linear',
                legend: { position: 'bottom' },
                height: 400,
                width: "100%",
                colors: ["#24695c"],
                pointSize: 5,
                annotations: {
                    textStyle: {
                        fontSize: 12,
                        color: '#000',
                    }
                },
                vAxis: {
                    format: "0",
                    viewWindow: { min: 0 },
                },
            };
        }
    },
    methods: {
        onChartReady(chart, google) {
            this.chartsLib = google;
        },
        getDataRegistrasi(start, end) {
            axios.get("/admin-api/dashboard/get-total-registration-monthly", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                params: { startDate: start, endDate: end, outputType: 'monthly' },
            })
            .then((response) => {
                const totalRegistration = response.data.data.totalRegistration;
                this.line_chart.chartData = [["Date", "Registrasi", { role: 'annotation' }]];

                for (const key in totalRegistration) {
                    this.line_chart.chartData.push([
                        totalRegistration[key].date_formatted,
                        totalRegistration[key].value,
                        totalRegistration[key].value.toString()
                    ]);
                }

                this.periode = `${start} - ${end}`;
            });
        },
        handleMonthFilter(filter) {
            this.getDataRegistrasi(filter.value[0], filter.value[1]);
        }
    },
};
</script>

<style scoped>
.chart-placeholder {
    height: 150px;
    background: #f8f9fa;
    border-radius: 5px;
    text-align: center;
    line-height: 150px;
    color: gray;
}
</style>
