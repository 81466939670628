<template>
    <div class="card p-3">
        <h6>{{ title }}</h6>
        <div class="d-flex mb-3">
            <button v-for="(filter, index) in timeFilters" :key="index" class="btn me-2"
                :class="{ 'btn-success': selectedFilter === filter, 'btn-outline-secondary': selectedFilter !== filter }"
                @click="selectedFilter = filter">
                {{ filter }}
            </button>
        </div>
        <div class="chart-placeholder">
            <GChart class="chart-overflow" id="column-chart1" :settings="settings" :data="column_chart.chartData_1"
                :options="verticalChartOptions" :createChart="(el, google) => new google.charts.Bar(el)"
                @ready="onChartReady" />
        </div>
    </div>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
    components: { GChart },
    props: ["title", "timeFilters"],
    data() {
        return {
            selectedFilter: this.timeFilters[0],
            chartsLib: null,
            column_chart: {
                chartData_1: [
                    ["Date", "Registration"],
                    ["13 Feb 2025", 8],
                    ["14 Feb 2025", 21],
                    ["15 Feb 2025", 3],
                    ["16 Feb 2025", 0],
                ],
            },
            settings: { packages: ['bar'] },
        };
    },
    computed: {
        verticalChartOptions() {
            if (!this.chartsLib) return null;
            return this.chartsLib.charts.Bar.convertOptions({
                chart: {
                    title: "Total Registration",
                    subtitle: "Sales, Expenses, and Profit: 2014-2017",
                },
                bars: "vertical",
                vAxis: {
                    format: "decimal",
                },
                height: 400,
                width: "100%",
                colors: ["#24695c", "#ba895d", "#e2c636"],
            });
        },
    },
    methods: {
        onChartReady(chart, google) {
            this.chartsLib = google;
        },
    },
};
</script>

<style scoped>
.chart-placeholder {
    height: 150px;
    background: #f8f9fa;
    border-radius: 5px;
    text-align: center;
    line-height: 150px;
    color: gray;
}
</style>