<template>
  <Breadcrumbs main="Customer" title="Distributor List" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-8">
                <!-- filter and search  -->
                <MonthFilter @updateFilter="handleMonthFilter" />
                <div class="d-flex justify-content-between mb-0 mt-2">
                  <div class="d-flex">
                    <div class="me-2">
                      <div class="input-group class-form">
                        <input type="search" style="width: 100% !important; height: 45px" placeholder="Pencarian"
                          class="form-control" @keyup.enter="cari()" v-model="filterQuery" />
                      </div>
                    </div>

                    <div class="mx-2 mt-2">
                      <div class="d-flex">
                        <div>
                          <vue-feather class="btn btn-xs btn-outline-success me-3" @click="cari()" size="20"
                            type="search">
                          </vue-feather>
                        </div>
                        <div>
                          <vue-feather class="btn btn-xs btn-outline-primary me-3" @click="reset" size="20"
                            type="x-square">
                          </vue-feather>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered" id="tabledt">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Code</th>
                  <th scope="col">Nama</th>
                  <th scope="col">Jumlah Toko</th>
                  <th scope="col">Total Transaksi</th>
                  <th scope="col">Region</th>
                  <th scope="col">Area</th>
                </tr>
              </thead>
              <tbody v-show="isLoading == false">
                <tr v-for="item in items" :key="item">
                  <td style="display: none" id="iditem">{{ item.id }}</td>
                  <td>{{ item.no }}</td>
                  <td>{{ item.code }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.total_customers }}</td>
                  <td>{{ "Rp. " + parseFloat(item.total_grand_total).toLocaleString('id-ID') }}</td>
                  <td>{{ item.region }}</td>
                  <td>{{ item.area }}</td>
                </tr>
              </tbody>
            </table>

            <div class="loader-box" v-show="isLoading == true">
              <div class="loader-2"></div>
            </div>
            <div v-show="isLoading == false && items.length == 0" class="px-4">
              <span>Tidak ada data</span>
            </div>
          </div>

          <!-- row and pagination  -->
          <div class="flex">
            <div class="d-flex justify-content-end mb-2">
              <div class="mt-4 mx-3">
                <span>Menampilkan {{ firstNo + " - " + lastNo + " Hasil " + meta.total }}</span>
              </div>

              <!-- pagination  -->
              <div class="mt-4 mx-3">
                <vue-awesome-paginate v-show="items.length > 0 && isload == false" :total-items="meta.total"
                  :items-per-page="meta.perPage" :max-pages-shown="meta.perPage" v-model="page" @click="changePage" />
              </div>

              <div class="mt-n1 mx-4">
                <div class="dataTables_length" id="basic-1_length" style="text-align: left">
                  <p>
                    <small>Tampilkan</small>
                    <select name="basic-1_length" aria-controls="basic-1" class="form-control" @change="showper"
                      v-model="perPage">
                      <option v-for="option in pageOptions" :key="option" :options="pageOptions">{{ option }}</option>
                    </select>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- end of row and pagination  -->
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <DeleteBank />
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";
import DeleteBank from "@/components/bank/bank_delete.vue";
import MonthFilter from '../default/MonthFilter.vue';

export default {
  components: {
    VueFeather,
    DeleteBank,
    MonthFilter
  },
  data() {
    return {
      user: [],
      ceklist: [],
      meta: [],
      firstNo: null,
      lastNo: null,
      No: 0,
      distributorIds: [],
      isLoading: false,
      filterPublik: "",
      filterStatus: "",
      textTag: false,
      filterOpt: false,
      tooltipTag: false,
      valueTag: [],
      valueDistributor: [],
      NoPage: 1,
      isload: false,
      items: [],
      dataTag: [],
      perPage: 10,
      filterQuery: "",
      selectedData: [],
      headers: [
        { text: "#", value: "#", sortable: true },
        { text: "Judul", value: "title", sortable: true },
        { text: "DiBuat", value: "createdAt", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Status Publik", value: "isPublic", sortable: true },
      ],
      itemlocal: [],
      dataDistributor: [],
      page: 1,
      search: "",
      pageOptions: [5, 10, 20, 50, 100],
      filterTanggal: []
    };
  },
  async created() {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setMonth(endDate.getMonth() - 3);

    // Format to YYYY-MM-DD
    this.filterTanggal = [startDate.toLocaleDateString('en-CA'), endDate.toLocaleDateString('en-CA')];
    this.getdata();
  },
  methods: {
    getdata() {
      this.isLoading = true;
      axios
        .get("/admin-api/dashboard/get-distributor-by-gmv?page=" + this.page + "&perPage=" + this.perPage, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: { startDate: this.filterTanggal[0], endDate: this.filterTanggal[1] },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.distributors.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.distributors;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
          this.isLoading = false;
        });
    },
    getRow() {
      var grid = document.getElementById("tabledt");
      var checkBoxes = grid.getElementsByTagName("INPUT");
      let data = [];
      for (var i = 0; i < checkBoxes.length; i++) {
        if (checkBoxes[i].checked) {
          data.push(checkBoxes[i].parentNode.parentNode.cells[0].innerText);
        }
      }
      this.selectedData = data;
    },

    selectDistributor(o) {
      this.valueDistributor.push(o.id);
    },

    filter() {
      this.isLoading = true;
      let params = {
        distributorIds: this.valueDistributor,
      };
      let valTag = Object.keys(params).map((key) => {
        if (Array.isArray(params[key])) {
          return params[key].map((value) => `${key}[]=${value}`).join("&");
        }
      });
      axios
        .get(
          "/admin-api/dashboard/get-distributor-by-gmv?perPage=" +
          this.perPage +
          "&" +
          valTag[0] +
          "&search=" +
          this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.distributors.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.distributors;
          this.filteritems = this.items;
          this.meta = x.data.data.meta;
          this.filterOpt = true;
          this.isLoading = false;
        });
    },

    reset() {
      this.isLoading = true;
      this.valueTag = [];
      this.filterQuery = "";
      this.filterPublik = "";
      this.filterStatus = "";
      this.getdata();
      this.filterOpt = false;
      this.isLoading = false;
    },

    cari() {
      this.isLoading = true;
      this.page = 1;
      axios
        .get(
          "/admin-api/dashboard/get-distributor-by-gmv?page=1" +
          "&perPage=" +
          this.perPage +
          "&search=" +
          this.filterQuery,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            params: { startDate: this.filterTanggal[0], endDate: this.filterTanggal[1] }
          }
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.distributors.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.distributors;
          this.meta = x.data.data.meta;
          this.isLoading = false;
        });
    },

    getDistributor() {
      axios
        .get("/admin-api/dashboard/get-distributor-by-gmv", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: { startDate: start, endDate: end },
        })
        .then((x) => {
          this.dataDistributor = x.data.data.distributors;
        });
    },

    changePage(x) {
      this.isLoading = true;
      this.page = x;
      axios
        .get(
          "/admin-api/dashboard/get-distributor-by-gmv?page=" +
          this.page +
          "&perPage=" +
          this.perPage +
          "&search=" +
          this.filterQuery,
          { 
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            params: { startDate: this.filterTanggal[0], endDate: this.filterTanggal[1] }
          }
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.distributors.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.distributors;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
          this.isLoading = false;
        });
    },

    asyncDistributorrSearch(q) {
      axios
        .get("admin-api/dashboard/get-distributor-by-gmv?search=" + q, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: { startDate: start, endDate: end },
        })
        .then((x) => {
          this.dataDistributor = x.data.data.distributors;
        });
    },

    customers(x) {
      this.$store.state.BannerCustomers = x;
    },

    getTag() {
      axios
        .get("/admin-api/banner/list-banner-tags", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: { startDate: start, endDate: end },
        })
        .then((x) => {
          let data = x.data.data.bannerTags;
          let res = data.map((c) => {
            return Object.values(c);
          });
          res = res.flat();
          res = [...new Set(res)];
          this.dataTag = res;
        });
    },

    showper() {
      this.isLoading = true;
      this.NoPage = 1;
      this.page = 1;
      axios
        .get(
          "/admin-api/dashboard/get-distributor-by-gmv?page=" +
          this.page +
          "&perPage=" +
          this.perPage +
          "&search=" +
          this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.distributors.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.distributors;
          this.meta = x.data.data.meta;
          this.isLoading = false;
        });
    },

    editRow(x) {
      this.$store.state.dataBank = x;
    },

    deleteRow(x) {
      this.$store.state.BankDeleteId = x.id;
    },

    setImagePreview(x) {
      this.$store.state.ImageBanner = x.image;
      this.$store.state.setImage();
    },

    addTag(event) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        if (this.valueTag.length >= 1) {
          for (let i = 0; i < this.valueTag.length; i++) {
            if (this.valueTag[i] === val) {
              return false;
            }
          }
        }
        this.valueTag.push(val);
        event.target.value = "";
        this.textTag = false;
      }
    },
    removeTag(index) {
      this.valueTag.splice(index, 1);
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.valueTag.length - 1);
      }
    },
    handleMonthFilter(filter) {
      console.log('Selected filter:', filter);

      this.filterTanggal = filter.value;
      this.cari();
    }
  },
};
</script>
<style>
.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.activeFont {
  font-size: 0.8rem;
}

.tag-input {
  width: 50%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 20px;
  box-sizing: border-box;
  padding: 0 10px;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #d22d3d;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
  font-family: "Roboto";
}

.tag-input__tag>span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}

.paginate-buttons {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: darkred;
  border: 1px solid darkred;
  color: white;
}

.active-page:hover {
  background-color: darkred;
}
</style>
