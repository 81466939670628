const menu = [
  {
    headTitle1: "Administrator Tools",
    type: "headtitle",
  },
  {
    title: "Data Sync",
    icon: "refresh-cw",
    type: "sub",
    active: false,
    children: [
      {
        path: "/data_sync/master",
        title: "Sync Master Data",
        type: "link",
      },
      {
        path: "/data_sync/salesman_customer",
        title: "Salesman & Customer",
        type: "link",
      },
    ],
  },
  {
    title: "App Settings",
    icon: "settings",
    badgeType: "light-primary",
    badgeValue: "2",
    active: false,
    type: "link",
    path: "/app-settings/list",
  },
  // {
  //   title: "Query Interface",
  //   icon: "database",
  //   badgeType: "light-primary",
  //   badgeValue: "2",
  //   active: false,
  //   type: "link",
  //   path: "/query-interface",
  // },
  {
    title: "Features",
    icon: "settings",
    badgeType: "light-primary",
    badgeValue: "2",
    active: false,
    type: "link",
    path: "/features/list",
  },
];

export default menu;
