<template>
    <div class="d-flex mb-3">
        <button v-for="(option, index) in options" :key="index" @click="selectFilter(option.value)" class="btn me-2"
            :class="{ 'btn-success': selectedFilter === option.value, 'btn-outline-secondary': selectedFilter !== option.value }">
            {{ option.label }}
        </button>
        <button @click="showModal = true" class="btn me-2"
            :class="{ 'btn-success': selectedFilter === '', 'btn-outline-secondary': selectedFilter !== '' }">
            Pilih Tanggal
        </button>
    </div>
    <!-- Date Range Picker Modal -->
    <div v-if="showModal" class="modal fade show d-block" tabindex="-1" aria-labelledby="datePickerModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-top">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="datePickerModalLabel">Pilih Rentang Tanggal</h5>
                    <button type="button" class="btn-close" @click="showModal = false"></button>
                </div>
                <div class="modal-body">
                    <DatePicker v-model="dateRange" range />
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="showModal = false">Cancel</button>
                    <button type="button" class="btn btn-success" @click="applyDateRange">Apply</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const selectedFilter = ref('7');
const showModal = ref(false);
const dateRange = ref(null);
const emit = defineEmits(['updateFilter']);

const options = [
    { label: '7 hari', value: '7' },
    { label: '14 hari', value: '14' },
    { label: '30 hari', value: '30' }
];

const selectFilter = (value) => {
    if (selectedFilter != null) {
        selectedFilter.value = value;
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - parseInt(value, 10));

        // Format to YYYY-MM-DD
        const formattedRange = [startDate.toLocaleDateString('en-CA'), endDate.toLocaleDateString('en-CA')];

        emit('updateFilter', { type: 'preset', value: formattedRange });
    }
};

const applyDateRange = () => {
    showModal.value = false;
    selectedFilter.value = "";
    if (dateRange.value && dateRange.value.length === 2) {
        const formattedRange = dateRange.value.map(date => date.toISOString().split('T')[0]);
        emit('updateFilter', { type: 'custom', value: formattedRange });
    }
};
</script>