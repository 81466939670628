<template>
    <div class="card p-3">
        <h6>{{ title }}</h6>
        <MonthFilter @updateFilter="handleMonthFilter" />
        <div class="chart-placeholder">
            <GChart class="chart-overflow" id="column-chart1" :settings="settings" :data="column_chart.chartData"
                :options="chartOptions" :createChart="(el, google) => new google.visualization.ColumnChart(el)"
                @ready="onChartReady" />
        </div>

        <div v-if="showModal" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
            <div class="bg-white p-6 rounded-lg shadow-lg">
                <h3 class="text-lg font-semibold mb-3">Pilih Rentang Tanggal</h3>
                <DatePicker v-model="dateRange" range />
                <div class="mt-4 flex justify-end gap-2">
                    <button @click="showModal = false" class="px-4 py-2 bg-gray-300 rounded">Cancel</button>
                    <button @click="applyDateRange" class="px-4 py-2 bg-green-700 text-white rounded">Apply</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { GChart } from "vue-google-charts";
import MonthFilter from '../default/MonthFilter.vue';

export default {
    components: { GChart, MonthFilter },
    props: ["title", "timeFilters"],
    data() {
        return {
            chartsLib: null,
            column_chart: {
                chartData: []
            },
            settings: { packages: ['corechart'] },
            periode: ""
        };
    },
    async created() {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 3);

        // Format to YYYY-MM-DD
        const formattedRange = [startDate.toLocaleDateString('en-CA'), endDate.toLocaleDateString('en-CA')];
        this.getDataOrder(formattedRange[0], formattedRange[1]);
    },
    computed: {
        chartOptions() {
            return {
                title: "Sales, Expenses, and Profit",
                subtitle: "Yearly Comparison",
                bars: "vertical",
                vAxis: {
                    format: "0",
                    viewWindow: { min: 0 }
                },
                height: 400,
                width: "100%",
                colors: ["#4285F4", "#DB4437", "#F4B400", "#2ecc71", "#bdc3c7", "#9b59b6"],
            };
        }
    },
    methods: {
        onChartReady(chart, google) {
            this.chartsLib = google;
        },
        getDataOrder(start, end) {
            axios
            .get("/admin-api/dashboard/get-toko-order-region-monthly", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                params: { startDate: start, endDate: end },
            })
            .then((response) => {
                const data = response.data.data.totalOrder;

                this.column_chart.chartData = [
                    ["Date", "Region 1", "Region 2", "Region 3", "Region 4", "Region 5", "Region 6"]
                ];

                // Mocked data for now - replace with real API data
                // this.column_chart.chartData.push(["2014", 1000, 400, 200]);
                for (const key in data) {
                    this.column_chart.chartData.push([
                        data[key].date_formatted, // Date
                        data[key].regional_1,
                        data[key].regional_2,
                        data[key].regional_3,
                        data[key].regional_4,
                        data[key].regional_5,
                        data[key].regional_6
                    ]);
                }

                this.periode = `${start} - ${end}`;
            });
        },
        handleMonthFilter(filter){
            console.log('Selected filter:', filter);

            this.getDataOrder(filter.value[0], filter.value[1]);
        }
    },
};
</script>

<style scoped>
.chart-placeholder {
    height: 400px;
    background: #f8f9fa;
    border-radius: 5px;
    text-align: center;
    line-height: 400px;
    color: gray;
}
</style>
