<template>
    <div class="col mb-3 d-flex">
      <div class="card text-center p-3 w-100 d-flex flex-column justify-content-center">
        <h6>{{ title }}</h6>
        <h4>{{ value.toLocaleString('id-ID') }}</h4>
        <small>{{ unit }}</small>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ["title", "value", "unit", "styling"]
  };
  </script>
  
  <style scoped>
  .card {
    height: 100%; /* Ensures all cards stretch to the same height */
    min-height: 120px; /* Prevents them from shrinking too much */
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  </style>