<template>
    <div class="card p-3">
        <h6>{{ title }}</h6>
        <MonthFilter @updateFilter="handleMonthFilter" />
        <h4>Table Top 10 distributor berdasarkan GMV</h4>
        <span>Periode : {{ periode }}</span>
        <div class="table-responsive mt-2">
            <table class="table table-striped table-hover table-bordered" id="tabledt">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Code</th>
                  <th scope="col">Nama</th>
                  <th scope="col">Jumlah Toko</th>
                  <th scope="col">Total Transaksi</th>
                  <th scope="col">Region</th>
                  <th scope="col">Area</th>
                </tr>
              </thead>
              <tbody v-show="isLoading == false">
                <tr v-for="item in items" :key="item">
                  <td style="display: none" id="iditem">{{ item.id }}</td>
                  <td>{{ item.no }}</td>
                  <td>{{ item.code }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.total_customers }}</td>
                  <td>{{ "Rp. " + parseFloat(item.total_grand_total).toLocaleString('id-ID') }}</td>
                  <td>{{ item.region }}</td>
                  <td>{{ item.area }}</td>
                </tr>
              </tbody>
            </table>
        </div>

        <div class="row mt-2">
            <div class="col-md-12">
                <router-link to="/dashboard/list-distributor-by-gmv" data-container="body" data-bs-placement="top" title="Add">
                    <button @click="showAllData" class="btn btn-sm btn-primary btn-lg" style="float: right;" type="button">
                        Tampilkan semua data
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { GChart } from "vue-google-charts";
import MonthFilter from '../default/MonthFilter.vue';

export default {
    components: { GChart, MonthFilter },
    data() {
        return {
            periode: "",
            items: [],
            No: 0,
        };
    },
    async created() {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 3);

        // Format to YYYY-MM-DD
        const formattedRange = [startDate.toLocaleDateString('en-CA'), endDate.toLocaleDateString('en-CA')];
        this.getDataOrder(formattedRange[0], formattedRange[1]);
    },
    methods: {
        getDataOrder(start, end) {
            axios
            .get("/admin-api/dashboard/get-distributor-by-gmv?page=1&perPage=10", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                params: { startDate: start, endDate: end },
            })
            .then((response) => {
                let num = 10 * 1;
                this.firstNo = num - 10 + 1;
                this.No = 1 * 10;
                this.No = this.No - 10;
                response.data.data.distributors.forEach((z) => {
                    this.No++;
                    z.no = this.No;
                });
                this.lastNo = this.No;
                this.items = response.data.data.distributors;
                this.meta = response.data.data.meta;
                this.itemlocal = this.items;
                this.isLoading = false;
            });

            this.periode = `${start} - ${end}`;
        },
        handleMonthFilter(filter){
            console.log('Selected filter:', filter);

            this.getDataOrder(filter.value[0], filter.value[1]);
        }
    },
};
</script>
