<template>
  <Breadcrumbs main="Promotion" title=" Promotion List" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-8">
                <!-- filter and search  -->
                <div class="d-flex justify-content-between mb-0 mt-2">
                  <div class="d-flex">
                    <div class="mx-2" style="width: 400px; margin-top: 2px">
                      <multiselect
                        v-model="distributorIds"
                        id="ajax"
                        label="name"
                        track-by="name"
                        :show-labels="false"
                        :option-height="34"
                        open-direction="bottom"
                        :options="dataDistributor"
                        :multiple="true"
                        :searchable="true"
                        :loading="isLoading"
                        :internal-search="false"
                        :clear-on-select="false"
                        :options-limit="300"
                        :limit="3"
                        placeholder="Distributor"
                        :max-height="600"
                        :hide-selected="false"
                        @select="selectDistributor"
                        @remove="removeDistributor"
                        @search-change="asyncDistributorrSearch"
                      >
                        <span slot="noResult">Tidak Menemukan Kata kunci.</span>
                      </multiselect>
                    </div>

                    <div class="me-2">
                      <div class="input-group class-form">
                        <input
                          type="search"
                          style="width: 100% !important; height: 45px"
                          placeholder="Pencarian"
                          class="form-control"
                          @keyup.enter="cari()"
                          v-model="filterQuery"
                        />
                      </div>
                    </div>

                    <div class="mx-2 mt-2">
                      <div class="d-flex">
                        <div>
                          <vue-feather
                            class="btn btn-xs btn-outline-success me-3"
                            @click="filter"
                            size="20"
                            type="search"
                          >
                          </vue-feather>
                        </div>
                        <div>
                          <vue-feather
                            class="btn btn-xs btn-outline-primary me-3"
                            @click="reset"
                            size="20"
                            type="x-square"
                          >
                          </vue-feather>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 mt-2">
                <div class="position-absolute top-0 end-0">
                  <ul>
                    <li>
                      <router-link to="/promotion/add" data-container="body" data-bs-placement="top" title="Add">
                        <vue-feather class="btn btn-xs btn-outline-primary" type="plus"> </vue-feather>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered" id="tabledt">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Title</th>
                  <th scope="col">Coupon Image</th>
                  <th scope="col">Card No</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Quota</th>
                  <th scope="col">Use on User</th>
                  <th scope="col">Status</th>
                  <th scope="col">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item">
                  <td style="display: none" id="iditem">{{ item.id }}</td>
                  <td>{{ item.no }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    <div style="width: 100px; height: 100px">
                      <img class="img-fluid img-xs img-thumbnail" :src="item.couponImage" />
                    </div>
                  </td>
                  <td>{{ item.code }}</td>
                  <td>
                    {{ item.startedAt }}
                  </td>
                  <td>
                    {{ item.endedAt }}
                  </td>
                  <td>
                    {{ item.quota }}
                  </td>
                  <td>
                    {{ item.quotaClaimed }}
                  </td>
                  <td>
                    <span :class="item.isActive == true ? 'badge badge-success' : 'badge badge-warning'">
                      {{ item.isActive == true ? "Aktif" : "Tidak Aktif" }}</span
                    >
                  </td>
                  <td>
                    <ul>
                      <li class="mb-1 mx-1">
                        <router-link
                          to="/promotion/edit"
                          data-container="body"
                          data-bs-placement="top"
                          title="Edit"
                          @click="editRow(item)"
                        >
                          <vue-feather class="btn btn-xs btn-outline-primary" size="18" type="edit"> </vue-feather>
                        </router-link>
                      </li>
                      <li class="mb-1 mx-1">
                        <router-link
                          to="/promotion/list"
                          data-container="body"
                          data-placement="top"
                          title="Delete"
                          data-bs-toggle="modal"
                          data-original-title="test"
                          data-bs-target="#deletePromotionModal"
                          @click="deleteRow(item)"
                        >
                          <vue-feather class="btn btn-xs btn-outline-primary" size="18" type="trash"> </vue-feather>
                        </router-link>
                      </li>
                      <li class="btn btn-xs btn-primary-light mb-1 mx-1">
                        <router-link
                          to="/promotion/customer-distributor"
                          data-container="body"
                          title="Customers"
                          @click="customers_distributor(item)"
                        >
                          <vue-feather size="20" type="user"> </vue-feather>
                        </router-link>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- row and pagination  -->
          <div class="flex">
            <div class="d-flex justify-content-end mb-2">
              <div class="mt-4 mx-3">
                <span>Menampilkan {{ firstNo + " - " + lastNo + " Hasil " + meta.total }}</span>
              </div>

              <!-- pagination  -->
              <div class="mt-4 mx-3">
                <vue-awesome-paginate
                  v-show="items.length > 0 && isload == false"
                  :total-items="meta.total"
                  :items-per-page="meta.perPage"
                  :max-pages-shown="meta.perPage"
                  v-model="page"
                  @click="changePage"
                />
              </div>

              <div class="mt-n1 mx-4">
                <div class="dataTables_length" id="basic-1_length" style="text-align: left">
                  <p>
                    <small>Tampilkan</small>
                    <select
                      name="basic-1_length"
                      aria-controls="basic-1"
                      class="form-control"
                      @change="showper"
                      v-model="perPage"
                    >
                      <option v-for="option in pageOptions" :key="option" :options="pageOptions">{{ option }}</option>
                    </select>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- end of row and pagination  -->
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <DeletePromotion />
</template>
<script>
import axios from "axios";
import VueFeather from "vue-feather";
import DeletePromotion from "@/components/promotion/promotion_delete.vue";

export default {
  components: {
    VueFeather,
    DeletePromotion,
  },
  data() {
    return {
      user: [],
      ceklist: [],
      meta: [],
      firstNo: null,
      lastNo: null,
      No: 0,
      distributorIds: [],
      isLoading: false,
      filterPublik: "",
      filterStatus: "",
      textTag: false,
      filterOpt: false,
      tooltipTag: false,
      valueTag: [],
      valueDistributor: [],
      NoPage: 1,
      isload: false,
      items: [],
      dataTag: [],
      perPage: 10,
      filterQuery: "",
      selectedData: [],
      headers: [
        { text: "#", value: "#", sortable: true },
        { text: "Judul", value: "title", sortable: true },
        { text: "DiBuat", value: "createdAt", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Status Publik", value: "isPublic", sortable: true },
      ],
      itemlocal: [],
      dataDistributor: [],
      page: 1,
      search: "",
      pageOptions: [5, 10, 20, 50, 100],
    };
  },

  mounted() {
    this.user = localStorage.getItem("user");
    this.getDistributor();
    this.getdata();
    this.$store.state.getdataPromotion = this.getdata;
  },
  methods: {
    getdata() {
      axios
        .get("/admin-api/promotion/list-promotions?page=" + this.page + "&perPage=" + this.perPage, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.promotions.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.promotions;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
        });
    },
    getRow() {
      var grid = document.getElementById("tabledt");
      var checkBoxes = grid.getElementsByTagName("INPUT");
      let data = [];
      for (var i = 0; i < checkBoxes.length; i++) {
        if (checkBoxes[i].checked) {
          data.push(checkBoxes[i].parentNode.parentNode.cells[0].innerText);
        }
      }
      this.selectedData = data;
    },

    selectDistributor(o) {
      this.valueDistributor.push(o.id);
    },

    // TODO Remove Distributor?
    removeDistributor(o) {},

    filter() {
      let params = {
        distributorIds: this.valueDistributor,
      };
      let valTag = Object.keys(params).map((key) => {
        if (Array.isArray(params[key])) {
          return params[key].map((value) => `${key}[]=${value}`).join("&");
        }
      });
      axios
        .get(
          "/admin-api/promotion/list-promotions?perPage=" +
            this.perPage +
            "&" +
            valTag[0] +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.promotions.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.promotions;
          this.filteritems = this.items;
          this.meta = x.data.data.meta;
          this.filterOpt = true;
        });
    },

    reset() {
      this.valueTag = [];
      this.filterPublik = "";
      this.filterStatus = "";
      this.filter();
      this.filterOpt = false;
    },

    cari() {
      axios
        .get(
          "/admin-api/promotion/list-promotions?page=" +
            this.page +
            "&perPage=" +
            this.perPage +
            "&search=" +
            this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.banners.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.banners;
          this.meta = x.data.data.meta;
        });
    },

    getDistributor() {
      axios
        .get("/admin-api/distributor/list-distributors", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.dataDistributor = x.data.data.distributors;
        });
    },

    changePage(x) {
      this.page = x;
      let params = {
        distributorIds: this.valueDistributor,
      };
      let valTag = Object.keys(params).map((key) => {
        if (Array.isArray(params[key])) {
          return params[key].map((value) => `${key}[]=${value}`).join("&");
        }
      });
      axios
        .get("/admin-api/promotion/list-promotions?page=" + this.page + "&perPage=" + this.perPage +
          "&" +
          valTag[0] +
          "&search=" +
          this.filterQuery, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.promotions.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.promotions;
          this.meta = x.data.data.meta;
          this.itemlocal = this.items;
        });
    },

    asyncDistributorrSearch(q) {
      axios
        .get("admin-api/distributor/list-distributors?search=" + q, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          this.dataDistributor = x.data.data.distributors;
        });
    },

    customers(x) {
      this.$store.state.BannerCustomers = x;
    },

    getTag() {
      axios
        .get("/admin-api/banner/list-banner-tags", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((x) => {
          let data = x.data.data.bannerTags;
          let res = data.map((c) => {
            return Object.values(c);
          });
          res = res.flat();
          res = [...new Set(res)];
          this.dataTag = res;
        });
    },

    showper() {
      this.isload = true;
      this.NoPage = 1;
      this.page = 1;
      axios
        .get(
          "/admin-api/banner/list-bank?page=" + this.page + "&perPage=" + this.perPage + "&search=" + this.filterQuery,
          { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
        )
        .then((x) => {
          let num = this.perPage * this.page;
          this.firstNo = num - this.perPage + 1;
          this.No = this.page * this.perPage;
          this.No = this.No - this.perPage;
          x.data.data.promotions.forEach((z) => {
            this.No++;
            z.no = this.No;
          });
          this.lastNo = this.No;
          this.items = x.data.data.promotions;
          this.meta = x.data.data.meta;
          this.isload = false;
        });
    },

    editRow(x) {
      this.$store.state.dataPromotion = x;
    },

    deleteRow(x) {
      this.$store.state.PromotionDeleteId = x.id;
    },

    setImagePreview(x) {
      this.$store.state.ImageBanner = x.image;
      this.$store.state.setImage();
    },

    addTag(event) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        if (this.valueTag.length >= 1) {
          for (let i = 0; i < this.valueTag.length; i++) {
            if (this.valueTag[i] === val) {
              return false;
            }
          }
        }
        this.valueTag.push(val);
        event.target.value = "";
        this.textTag = false;
      }
    },
    removeTag(index) {
      this.valueTag.splice(index, 1);
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.valueTag.length - 1);
      }
    },
    customers_distributor(x) {
      this.$store.state.dataPromotionCustomer = x;
    },
  },
};
</script>
<style>
.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.activeFont {
  font-size: 0.8rem;
}

.tag-input {
  width: 50%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 4px;
  font-size: 0.9em;
  min-height: 20px;
  box-sizing: border-box;
  padding: 0 10px;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #d22d3d;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
  font-family: "Roboto";
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}

.paginate-buttons {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: darkred;
  border: 1px solid darkred;
  color: white;
}

.active-page:hover {
  background-color: darkred;
}
</style>
